import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Container, Grid, Typography} from '@material-ui/core';
import Header from '../Header';
import Grow from '@material-ui/core/Grow';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '1000px',
    marginTop: theme.spacing(10)
  },
  paragraph: {
    fontFamily: 'inherit',
    fontSize: '16px',
    [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
    },
  },
  image: {
    borderRadius: '50% 40% 60% 40%',
    boxShadow: '0px 0px 6px 4px rgba(0,0,0,0.1)',
    margin: '0 auto',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    textAlign: 'center',
    display: 'inline-block'
  },
  image2: {
    width: '100%'
  },
  tfiy: {
    fontFamily: 'inherit',
    // textAlign: 'center',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  }
}))

function About() {
  const classes = useStyles();
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(prev => !prev);
    window.scrollTo(0, 0);
  }, [])

  return (
    <Grow in={checked}>
      <Container className={classes.root}>
        <Header variant="h2">About Us</Header>
        <Grid container direction="row" alignContent="space-between" alignItems="center">

          {/* <Grid item md={4} sm={4} xs={12} style={{textAlign: "center"}}>
            <img src="https://res.cloudinary.com/tfiy/image/upload/v1606952142/the-floor-is-yours/about-page/9N7A8170_azjk4u.jpg" alt="hello" height="260" className={classes.image} />
          </Grid> */}
          <Grid item md={8} sm={8} xs={12}>
            <Typography variant="body1" className={classes.paragraph}>
            Welcome to Aasana : Yoga and Dance Labs. We offer structured Dance and Yoga program to students of all age groups. Aasana was founded in 2004, to spread the joy of dance and yoga to build a happier and healthier community.
              <br/><br/>
              Aasana Dance Program (ADP): Through ADP, we offer a wide variety of dance styles including Bollywood, Hip Hop, Modern, Contemporary and Salsa that cater to all age groups and levels. <br/><br/>
              <br/>
            </Typography>
          </Grid>

          <Grid item md={8} sm={8} xs={12}>
            <Typography variant="body1" className={classes.paragraph}>
            Aasana Yoga Program (AYP): Our Yoga program (AYP) consists of flow yoga routines and ‘aasanas’ paired with music. Through these sessions we target all parts of your body including abs, hips, thighs and improve your core strength, foucs and overall flexibility.
              <br/><br/>
              For both, Dance and Yoga programs, students have the flexibility of joining us in-person or online through flexible payment options (Pay per class or Pay for a bundle - set of 4 classes).
            </Typography>
          </Grid>
          {/* <Grid item md={4} sm={4} xs={12} style={{textAlign: "center"}}>
            <img src="https://res.cloudinary.com/tfiy/image/upload/v1606952142/the-floor-is-yours/about-page/9N7A7528_d7uoip.jpg" alt="hello" height="260" className={classes.image} />
          </Grid> */}

          <Typography variant="body1" className={classes.tfiy}>
          We also offer personal training programs (in person/online) that are custom designed to suit your specific scheduling and fitness needs.
          We aim for smaller batch sizes for better guidance and instructions so registrations are limited. For more information contact us!
          We are now open for In-Person and Online Classes!
          </Typography>

          {/* <Grid item md={4} sm={4} xs={12} style={{textAlign: "center" }}>
            <img src="https://res.cloudinary.com/tfiy/image/upload/v1606952142/the-floor-is-yours/about-page/9N7A7803_uq4ona.jpg" alt="hello" width="280" className={classes.image} />
          </Grid>
          <Grid item md={4} sm={4} xs={12} style={{textAlign: "center"}}>
            <img src="https://res.cloudinary.com/tfiy/image/upload/v1606952142/the-floor-is-yours/about-page/9N7A7557_hwmnki.jpg" alt="hello" width="280" className={classes.image} />
          </Grid>
          <Grid item md={4} sm={4} xs={12} style={{textAlign: "center"}}>
            <img src="https://res.cloudinary.com/tfiy/image/upload/v1606952142/the-floor-is-yours/about-page/9N7A7938_bcv41d.jpg" alt="hello" width="280" className={classes.image} />
          </Grid> */}

        </Grid>
      </Container>
    </Grow>
  )
}

export default About;
