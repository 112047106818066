import React from 'react';
import {Grid} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import Logo from '../../Images/logo-no-background.png';
import SocialIcons from '../SocialIcons/SocialIcons';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(20),
    marginBottom: theme.spacing(5),
    textAlign: 'center',
    background: 'black',
    padding: '20px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(10)
    }
  },
  content: {
    color: '#f2f2f2',
    fontSize: '14px',
  },
  email: {
    textDecoration: 'none',
    color: 'inherit',
    fontWeight: 'bold'
  },
  social: {
    [theme.breakpoints.down('xs')]: {
      marginTop: '25px'
    }
  }
}))

export default function Footer() {
  const classes = useStyles();

  return (
    <Grid container  className={classes.root} justify="center">
      <Grid item sm={6} xs={12}>
        <p className={classes.content}><b>San Jose Location:</b> 913 Otto Ct, San Jose, CA 95132</p>
        <p className={classes.content}><b>Fremont Location:</b> 4644 Creekwood Dr, Fremont, CA 94555</p>
        <p className={classes.content}>
          <a href="tel:+1-201-212-7466" style={{color: 'inherit', textDecoration: 'none'}}>
          (201) 212-7466
          </a>
        </p>
        <p className={classes.content}>
          <a href="mailto:aasanastudio@gmail.com" className={classes.email}>aasanastudio@gmail.com</a>
        </p>
      </Grid>
      <div>
            <SocialIcons flexDirection="row" />
          </div>
    </Grid>
  )
}
