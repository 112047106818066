import React from 'react';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';

function SocialIcons({ flexDirection }) {

  let styles = {
    container: {
      display: 'flex',
      flexDirection: flexDirection,
    },
    link: {
      color: "white",
      margin: "0px 20px",
      fontFamily: 'inherit'
    },
  }

  return (
    <div style={styles.container}>
      <a href="https://www.facebook.com/profile.php?id=100088856212272" target="_blank" rel="noreferrer noopener">
          <FacebookIcon style={styles.link} fontSize="large"/>
      </a>
      <a href="https://www.instagram.com/aasanastudios/" target="_blank" rel="noreferrer noopener">
          <InstagramIcon style={styles.link} fontSize="large"/>
      </a>
    </div>
  )
}

export default SocialIcons;
