import React from 'react';
import Header from '../Header';
import {Link} from 'react-router-dom';
import {Typography, Container, Button, Grid} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import Background from '../../Images/classes.jpg';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(10),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse'
    }
  },
  imgItem: {
    alignSelf: 'center',
  },
  img: {
    width: '100%',
    // borderRadius: '60% 50% 60% 50%',
    // [theme.breakpoints.down('sm')]: {
    //   width: '100%',
    //   margin: '0 auto'
    // },
  },
  text: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingRight: theme.spacing(8),
    alignSelf: 'center',
  },
  link: {
    textDecoration: 'none',
    color: 'inherit'
  },
  btn: {
    color: 'var(--white)',
    border: '1px solid var(--white)'
  },
  content: {
    padding: theme.spacing(2),
    maxWidth: '850px',
    margin: '0 auto',
    fontSize: '14px',
    lineHeight: '28px',
    fontFamily: 'inherit',
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    }
  }
}))

function VirtualClasses() {
  const classes = useStyles();

  return (
    <Container>
      <Grid container className={classes.container}>
        <Grid item md={7} sm={12} className={classes.text}>
          <Typography className={classes.content}>
            <Header variant="h4">Learn about our classes</Header>
            We offer a wide range of classes teaching various dance styles including bollywood, hip hop, salsa, contemporary, flow yoga etc for students at all levels and age groups.
Our kids class focuses on having fun and teaching our students to enjoy music and learn the art of movement while instilling confidence in them.
<br /><br />
Our Adult classes are all about energy, building fitness and developing core strength while enjoying the physical and mental benefits of dance and movement.
<br /><br />
Pricipal instructor is a trained dancer and yoga teacher having several years of experience teaching at Shiamak Davars Institute of Performing Arts and several other private dance studios.

At Aasana, we allow single class drop-ins as well as bundle classes. Classes are currently being offered on weekdays and weekends at both locations - Fremont and San Jose.
<br /><br />
Register soon before batches are full!!
            <br/><br/>
            Click below to read about the classes we have to offer, meet the instructors and find out how you can still unleash your confidence.
            <br/><br/>
            <Button className={classes.btn} variant="outlined">
              <Link className={classes.link} to="/classes">
                View our classes
              </Link>
            </Button>
          </Typography>
        </Grid>
        <Grid item md={5} sm={8} className={classes.imgItem}>
          <img className={classes.img} src={Background} alt="pole-dancer"></img>
        </Grid>
      </Grid>
    </Container>
  )
}

export default VirtualClasses;
