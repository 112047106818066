import React from 'react';
import Header from '../Header';
import {Link} from 'react-router-dom';
import {Typography, Container, Grid, Button} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import Background from '../../Images/whoarewe.jpg';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(10),
    // height: '90vh',
    // background: 'red',
    // width: '100%',
  },
  imgItem: {
    alignSelf: 'center'
  },
  img: {
    width: '100%',
    // objectFit: 'contain',
    // borderRadius: '60% 50% 60% 50%',
  },
  text: {
    paddingLeft: theme.spacing(8),
    alignSelf: 'center',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(0),
      justifySelf: 'center'
    }
  },
  link: {
    textDecoration: 'none',
    color: 'inherit'
  },
  btn: {
    color: 'var(--white)',
    border: '1px solid var(--white)'
  },
  content: {
    padding: theme.spacing(2),
    maxWidth: '850px',
    margin: '0 auto',
    fontSize: '16px',
    lineHeight: '28px',
    fontFamily: 'inherit',
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    }
  }
}))

function WhoAreWe() {
  const classes = useStyles();

  return (
    <Container>
    <Grid container className={classes.container}>
      <Grid item md={5} sm={8} className={classes.imgItem}>
        <img className={classes.img} src={Background} alt="silks-acrobat"/>
      </Grid>
      <Grid item md={7} sm={12} className={classes.text}>
        <Typography className={classes.content}>
          <Header variant="h4">Who are we?</Header>
          Aasana was founded in 2004, to spread the joy of dance and yoga to build a happier and healthier community.
          <br/><br/>
Aasana Dance Program (ADP): Through ADP, we offer a wide variety of dance styles including Bollywood, Hip Hop, Modern, Contemporary and Salsa that cater to all age groups and levels.
<br/><br/>
Aasana Yoga Program (AYP): Our Yoga program (AYP) consists of flow yoga routines and ‘aasanas’ paired with music. Through these sessions we target all parts of your body including abs, hips, thighs and improve your core strength, foucs and overall flexibility.
          <br/><br/>
          <Button className={classes.btn} variant="outlined">
            <Link className={classes.link} to="/about">
              Learn more
            </Link>
          </Button>
        </Typography>
      </Grid>
    </Grid>
    </Container>
  )
}

export default WhoAreWe;
