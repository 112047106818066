export const classArray = [
  {
    id: 1,
    type: 'Bollywood Juniors (Age 4-7)',
    description: 'Bollywood Junior class teaches Bollywood style dance on popular dance numbers to kids between age 4 - 7. Classes start at 15$ per class',
    instructor: 'Swaroop',
    image: 'bolly_jr.gif',
    secondary_image: 'https://res.cloudinary.com/tfiy/image/upload/v1606949294/the-floor-is-yours/instructors/instructors_b/9N7A7940_slexlp.jpg',
    iframeURL: 'https://www.youtube.com/embed/xJ5ct84-nmw'
  },
  {
    id: 2,
    type: 'Contemporary Junior (Age 4-7)',
    description: 'Contemporary Junior class teaches contemporary style dance movement to kids between Age 4 - 7. Classes start at 15$ per class',
    instructor: 'Swaroop',
    image: 'contem_jr.jpeg',
    secondary_image: 'https://res.cloudinary.com/tfiy/image/upload/v1606949294/the-floor-is-yours/instructors/instructors_b/9N7A7488_vng0y4.jpg',
    iframeURL: 'https://www.youtube.com/embed/xJ5ct84-nmw'
  },
  {
    id: 3,
    type: 'Flow Yoga Junior (Age 4-7)',
    description: 'Flow Yoga class teaches foundations of Yoga movement to improve flexibility for kids between Age 4 - 7. Classes start at 15$ per class',
    instructor: 'Swaroop',
    image: 'yoga_jr.jpeg',
    secondary_image: 'https://res.cloudinary.com/tfiy/image/upload/v1606949294/the-floor-is-yours/instructors/instructors_b/9N7A7709_lo22v1.jpg',
    iframeURL: 'https://www.youtube.com/embed/xJ5ct84-nmw'
  },
  {
    id: 4,
    type: 'Bollywood Intermediate (Age 8-10)',
    description: 'Bollywood Intermediate class teaches Bollywood style dance on popular dance numbers to kids between age 8 - 10. Classes start at 15$ per class',
    instructor: 'Swaroop',
    image: 'bolly_inter.jpg',
    secondary_image: 'https://res.cloudinary.com/tfiy/image/upload/v1606949294/the-floor-is-yours/instructors/instructors_b/9N7A7940_slexlp.jpg',
    iframeURL: 'https://www.youtube.com/embed/xJ5ct84-nmw'
  },
  {
    id: 5,
    type: 'Contemporary Intermediate (Age 8-10)',
    description: 'Contemporary Intermediate class teaches contemporary style dance movement to kids between Age 8-10. Classes start at 15$ per class',
    instructor: 'Swaroop',
    image: 'contemporary_inter.jpg',
    secondary_image: 'https://res.cloudinary.com/tfiy/image/upload/v1606949294/the-floor-is-yours/instructors/instructors_b/9N7A7488_vng0y4.jpg',
    iframeURL: 'https://www.youtube.com/embed/xJ5ct84-nmw'
  },
  {
    id: 6,
    type: 'Flow Yoga Intermediate (Age 8-10)',
    description: 'Flow Yoga Intermediate teaches foundations of Yoga movement to improve flexibility for students of all age groups. Classes start at 15$ per class',
    instructor: 'Swaroop',
    image: 'inter_yoga.jpg',
    secondary_image: 'https://res.cloudinary.com/tfiy/image/upload/v1606949294/the-floor-is-yours/instructors/instructors_b/9N7A7709_lo22v1.jpg',
    iframeURL: 'https://www.youtube.com/embed/xJ5ct84-nmw'
  },
  {
    id: 7,
    type: 'Bollywood Plus',
    description: 'Bollywood Plus class teaches Bollywood style dance on popular dance numbers to students Age 10+. Classes start at 15$ per class',
    instructor: 'Swaroop',
    image: 'Bollywood_adults.jpg',
    secondary_image: 'https://res.cloudinary.com/tfiy/image/upload/v1606949294/the-floor-is-yours/instructors/instructors_b/9N7A7940_slexlp.jpg',
    iframeURL: 'https://www.youtube.com/embed/xJ5ct84-nmw'
  },
  {
    id: 8,
    type: 'Contemporary Plus',
    description: 'Contemporary Plus class teaches contemporary style dance movement to students Age 10+. Classes start at 15$ per class',
    instructor: 'Swaroop',
    image: 'adult_contemporary.jpg',
    secondary_image: 'https://res.cloudinary.com/tfiy/image/upload/v1606949294/the-floor-is-yours/instructors/instructors_b/9N7A7488_vng0y4.jpg',
    iframeURL: 'https://www.youtube.com/embed/xJ5ct84-nmw'
  },
  {
    id: 9,
    type: 'Flow Yoga Plus',
    description: 'Flow Yoga Plus teaches foundations of Yoga movement to improve flexibility for students of all age groups. Classes start at 15$ per class',
    instructor: 'Swaroop',
    image: 'yoga_adults.jpg',
    secondary_image: 'https://res.cloudinary.com/tfiy/image/upload/v1606949294/the-floor-is-yours/instructors/instructors_b/9N7A7709_lo22v1.jpg',
    iframeURL: 'https://www.youtube.com/embed/xJ5ct84-nmw'
  },
];
