import React, {useState, useEffect} from 'react';
import Background from '../../Images/dance_background.jpeg';
import Video from '../../Images/aasana_video.mp4';
import {Link} from 'react-router-dom';
import Grow from '@material-ui/core/Grow';
import WhoAreWe from './WhoAreWe';
import VirtualClasses from './VirtualClasses';
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Button} from '@material-ui/core';
import Header from '../Header';

const useStyles = makeStyles(theme => ({
  homePage: {
    height: '90vh',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    margin: '10vh auto',
    // background: 'blue',
  },
  flex: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
    },
  },
  // iframe: {
  //   width: '560px',
  //   height: '316px',
  //   [theme.breakpoints.down('lg')]: {
  //     width: '560px',
  //     height: '316px',
  //   },
  //   [theme.breakpoints.down('md')]: {
  //     width: '461px',
  //     height: '260px',
  //   },
  //   [theme.breakpoints.down('sm')]: {
  //     width: '355px',
  //     height: '200px',
  //   },
  // },
  image: {
    position: 'absolute',
    top: '0',
    width: '100%',
    minHeight: '100%',
    // height: '90vh',
    zIndex: '-1',

    // height: '40px',
    // [theme.breakpoints.down('sm')]: {
    //   width: '400px'
    // },
  },
  video: {
    // position: 'absolute',
    // top: '0',
    minWidth: '100%',
    width: '100%',
    minHeight: '100%',
    // height: '90vh',
    zIndex: '-1',

    // height: '40px',
    // [theme.breakpoints.down('sm')]: {
    //   width: '400px'
    // },
  },
  videocontainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  link: {
    textDecoration: 'none',
    color: 'inherit'
  },
  btn: {
    color: 'var(--black)',
    border: '1px solid var(--white)',
    background: 'white'
  }
}));

function Home() {
  const [checked, setChecked] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    setChecked(prev => !prev)
  }, [])

  return (
    <Grow in={checked}>
      <div>
        <div className={classes.homePage}>
          <Grid container className={classes.flex}>
            {/* <Grid item md={6} sm={12} xs={12} align="center">
              <iframe
                src="https://www.youtube.com/embed/T1-9UHfP4e4"
                className={classes.iframe}
                frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen>
              </iframe>
            </Grid> */}
            <Grid item md={6} sm={12} xs={12} align="center">
            <div className={classes.videocontainer}>
            {/* <video src={Video} autoPlay loop muted className={classes.video} /> */}
            <img src={Background} className={classes.image}/>
          <Header variant="h4">Dance and Yoga classes for kids and adults in Fremont and San Jose!</Header>
          <a href="https://forms.gle/HtRMaF4FcRfFdKCs9" target="_blank" rel="noreferrer noopener" className={classes.link}>
          <Button className={classes.btn} variant="outlined">
              Register Now
          </Button>
          </a>
          </div>
              {/* <img src={Logo} alt="The Floor is Yours Dance Center" className={classes.image} /> */}
            </Grid>
          </Grid>
        </div>
        <Grid container>
          <Grid item sm={12}>
            <WhoAreWe />
            <VirtualClasses />
          </Grid>
        </Grid>
      </div>
    </Grow>
  )
}

export default Home;
