import React, {useState, Fragment} from 'react';
import {Link} from 'react-router-dom';
import Logo from '../../Images/logo-white.png';
import WhatsApp from '../../Images/WhatsApp.png';
import Appbar from '@material-ui/core/AppBar';
// import SocialIcons from '../SocialIcons/SocialIcons';
import MenuIcon from '@material-ui/icons/MenuRounded';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
// import Waiver from '../../Images/TFIY_client-waiver.pdf';
import {
  makeStyles,
  Toolbar,
  IconButton,
  Button,
  Drawer
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    textDecoration: 'none',
    background: 'black',
    boxShadow: 'none',
    height: '10vh',
  },
  title: {
    textDecoration: 'none',
    flexGrow: 1,
    fontFamily: 'inherit'
  },
  name: {
    textDecoration: 'none',
    display: 'flex',
    flexDirection: 'row'
  },
  link: {
    textDecoration: 'none',
    textAlign: 'center',
    padding: '10px 0px',
    background: "var(--dark-gray)"
  },
  btn: {
    textDecoration: 'none',
    fontSize: '20px',
    fontFamily: 'inherit',
    fontWeight: 'bold',
    color: "var(--lightblue)",
    margin: theme.spacing(2)
  },
  letter: {
    textDecoration: 'none',
    // border: '1px solid white',
    marginLeft: '20px',
    // color: 'white',
    color: 'var(--white)',
    border: '1px solid var(--white)',
    // padding: '5px 20px'
  },
  noDecoration: {
    textDecoration: 'none',
  },
  logo: {
    width: '10vh',
    // marginTop: '20px'
  },
  whatsapp: {
    width: '5vh',
    // marginTop: '20px'
  },
  email: {
    textDecoration: 'none',
    color: 'inherit',
    fontWeight: 'bold'
  },
}));

function LetterButton() {
  const theme = useTheme();
  const classes = useStyles();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Fragment>
    {
      matches
      ?
      <Fragment>
        {/* <Link to="/"  className={classes.noDecoration}>
            <img src={Logo} className={classes.logo}/>
        </Link> */}
        <Link to="/about"  className={classes.noDecoration}>
          <Button className={classes.letter} variant="outlined">
            About Us
          </Button>
        </Link>
        <a href="https://forms.gle/HtRMaF4FcRfFdKCs9" target="_blank" rel="noreferrer noopener" className={classes.noDecoration}>
        {/* <Link> */}
          <Button className={classes.letter}>
            Register
          </Button>
        {/* </Link> */}
        </a>
      </Fragment>
      :
      null
    }
    </Fragment>
  )
}

function LetterNav() {
  const theme = useTheme();
  const classes = useStyles();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Fragment>
    {
      !matches
      ?
      <Fragment>
        <Link to="/about" className={classes.link}>
          <Button className={classes.btn}>
            About Us
          </Button>
        </Link>
        <a href="https://forms.gle/HtRMaF4FcRfFdKCs9" target="_blank" rel="noreferrer noopener" className={classes.link}>
        {/* <Link> */}
          <Button className={classes.btn}>
            Register
          </Button>
        {/* </Link> */}
        </a>
      </Fragment>
      :
      null
    }
    </Fragment>
  )
}



function Navbar() {
  const [menu, setMenu] = useState(false);
  const classes = useStyles();

  const toggleDrawer = (bool) => {
    !menu ? setMenu(bool) : setMenu(bool);
  }

  return (
      <Appbar className={classes.root}>
        <Toolbar style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>

          <div>

          <IconButton onClick={() => toggleDrawer(true)} edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
              <MenuIcon />
            </IconButton>
            <LetterButton />
          </div>

          <Drawer className={classes.name} anchor="bottom" open={menu} onClick={() => toggleDrawer(false)}>
            <Link to="/classes" className={classes.link}>
                <Button className={classes.btn}>
                  Classes
                </Button>
            </Link>
            <Link to="/contact" className={classes.link}>
                <Button className={classes.btn}>
                  Contact
                </Button>
            </Link>
            <LetterNav />
          </Drawer>
          <div>
          <Link to="/">
          <img src={Logo} className={classes.logo}/>
          </Link>
          </div>

          <div>
            <b><a href="tel:+1-201-212-7466" style={{color: 'inherit', textDecoration: 'none'}}>(201)-212-7466</a> | <a href="mailto:aasanastudios@gmail.com" className={classes.email}>aasanastudios@gmail.com </a></b>
          </div>
        </Toolbar>
      </Appbar>
  )
}

export default Navbar;
